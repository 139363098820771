import { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import { Typography, Box, Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SnackbarComponent from "../../Component/snackbarCom";
import profile from "../../logo/setting_profile.svg";
import privacy from "../../logo/setting_privacy.svg";
import lock from "../../logo/setting_lock.svg";
import Logout from "../../logo/logout.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useSelector } from "react-redux";
import avatar from "../../logo/avatar.svg";
import { disconnectSocket } from "../../utlis/socket";
import { getRequest } from "../../api/services";
import { useDispatch } from "react-redux";
import { updateUsers } from "../../Reducer/State/StepsReducer";

import "./setting.scss";

function Settings() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [is_loading, setIs_loading] = useState<boolean>(false);
  const UserInfo = useSelector((state: { user: any }) => state?.user.UserInfo);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarStatus, setSnackbarStatus] = useState(false);
  // console.log(UserInfo)

  useEffect(() => {
    disconnectSocket();
  }, []);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const logout = () => {
    localStorage.removeItem("token"); // Removes the item with key 'token'
    localStorage.removeItem("key"); // Replace 'key' with the actual key name
    navigate("/");
    window.location.reload();
  };

  const EditProfile = async () => {
    try {
      const url = "user/profile";
      const token = localStorage.getItem("token") ?? "";
      const response = await getRequest(url, token);
      if (response.status) {
        dispatch(updateUsers(response.data));
        navigate("/submit-details/upload-section/edit");
      }
    } catch (error) {
    //   console.log(error);
    }
  };

  const Element = [
    { icon: profile, name: "Profile", func: () => EditProfile() },
    { icon: privacy, name: "Privacy", func: () => navigate("/privacy-policy") },
    { icon: lock, name: "Change Password", func: () => console.log("a") },
    { icon: Logout, name: "Logout", func: logout },
  ];
  const Avator = UserInfo?.images ? UserInfo?.images[0] : avatar;
  return (
    <div className="setting_page">
      <Box
        className="component"
        sx={{
          width: {
            xs: "90%", // Small screens
            lg: "25%", // Large screens
          },
        }}
      >
        <IconButton className="setting_avatar">
          <Avatar alt="Remy Sharp" src={Avator} className="setting_image" />
        </IconButton>

        <div className="Button_boxes">
          {Element.map((x: any) => {
            return (
              <Box
                className="Setting_boxes"
                onClick={x.func}
                sx={{
                  width: {
                    xs: "90%", // Small screens
                    lg: "40%", // Large screens
                  },
                }}
              >
                <Box className="Button_Boxes">
                  <Box className="Icon-Button">
                    <img
                      src={x.icon} // Replace with the path to your image
                      alt="icon"
                    />
                  </Box>
                  <Typography variant="h3">{x.name}</Typography>
                </Box>
                <ArrowForwardIosIcon />
              </Box>
            );
          })}
        </div>
      </Box>
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={handleCloseSnackbar}
        status={snackbarStatus}
      />
    </div>
  );
}

export default Settings;
