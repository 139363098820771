import "./App.css";
import React, { useEffect, useRef } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Login from "./Pages/Login/index";
import Signup from "./Pages/SignUp";
import VerifyOTP from "./Pages/VerifyOTP";
// import SubmitDetailsForm from "./Pages/SubmitDetailsForm/index";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./styles/theme";
import PageLayout from "./Layout/layout";
import FindMatchesPage from "./Pages/FindMatch";
import SubmitDetailsForm from "./Layout/detailsLayout";
import Chats from "./Pages/Chats";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { getProfile } from "./utlis";
import { useDispatch } from "react-redux";
import Settings from "./Pages/Settings";
import UploadAlbums from "./Pages/SubmitDetailsForm/UploadAlbums";
import AboutMe from "./Pages/SubmitDetailsForm/AboutMe";
import Connect from "./Pages/SubmitDetailsForm/Connect";
import AboutMe2 from "./Pages/SubmitDetailsForm/AboutMe2";
import PrivacyPolicy from "./Pages/PrivacyPolicy";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const hasRefreshed = useRef(false);

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!hasRefreshed.current) {
      hasRefreshed.current = true;

      if (token) {
        getProfile(navigate, dispatch);
      } else if (location.pathname === "/privacy-policy") {
        navigate("/privacy-policy");
      } else {
        navigate("/");
      }
    }
    window.scrollTo(0, 0);
  }, [navigate, dispatch, location.pathname]);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Routes>
          <Route
            path="/"
            element={
              <GoogleOAuthProvider clientId="824563392946-gm5qpi6pts31ibi6r642lovjoop01o3e.apps.googleusercontent.com">
                <Login />
              </GoogleOAuthProvider>
            }
          />
          <Route path="/signup" element={<Signup />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/verify-otp" element={<VerifyOTP />} />
          {/* <Route path='/submit-details' element={<GoogleOAuthProvider clientId="824563392946-gm5qpi6pts31ibi6r642lovjoop01o3e.apps.googleusercontent.com"><SubmitDetailsForm /></GoogleOAuthProvider>} /> */}
          <Route
            path="/submit-details"
            element={
              <GoogleOAuthProvider clientId="824563392946-gm5qpi6pts31ibi6r642lovjoop01o3e.apps.googleusercontent.com">
                <SubmitDetailsForm />
              </GoogleOAuthProvider>
            }
          >
            <Route
              path="/submit-details/upload-section"
              element={<UploadAlbums />}
            />
            <Route path="/submit-details/looking-for" element={<AboutMe />} />
            <Route
              path="/submit-details/about-your-self"
              element={<AboutMe2 />}
            />
            <Route
              path="/submit-details/connect-to-social-media"
              element={<Connect />}
            />
            <Route
              path="/submit-details/upload-section/edit"
              element={<UploadAlbums />}
            />
            <Route
              path="/submit-details/looking-for/edit"
              element={<AboutMe />}
            />
            <Route
              path="/submit-details/about-your-self/edit"
              element={<AboutMe2 />}
            />
            <Route
              path="/submit-details/connect-to-social-media/edit"
              element={<Connect />}
            />
          </Route>
          <Route path="/profile" element={<PageLayout />}>
            <Route path="/profile/matches" element={<FindMatchesPage />} />
            <Route path="/profile/chats" element={<Chats />} />
            <Route path="/profile/chats/:id" element={<Chats />} />
            <Route path="/profile/setting" element={<Settings />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;
