import React from 'react';
import { Snackbar, Alert } from '@mui/material';

const SnackbarComponent = ({ open, message, onClose, status }: { open: boolean; message: string; onClose: () => void; status: boolean }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Position snackbar in the top right corner
    >
      <Alert 
        onClose={onClose} 
        severity={status ? 'success' : 'error'} // Set severity based on status
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarComponent;
