import { useRef, useState, useEffect } from "react";
import "./chats.scss";
// import { Box, IconButton, Typography, Avatar } from "@mui/material";
import {
  Box,
  Typography,
  Avatar,
  IconButton,
  TextField,
  InputAdornment,
  Divider,
  Button,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import search from "../../logo/search.svg";
import InputWithIcon from "../../Component/Input_with_icon";
import { useParams } from "react-router-dom";
import { initializeSocket } from "../../socket/socket";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateChatuser } from "../../Reducer/State/ChatingUser";
import Loader from "../../Component/loader";
import DoneAllTwoToneIcon from "@mui/icons-material/DoneAllTwoTone";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { postRequest } from "../../api/services";
import Modal from "@mui/material/Modal";
import CustomButton from "../../Component/button";

const SearchBarForm = {
  search: {
    image: search,
    type: "text",
    placeholder: "Search",
    value: "",
    message: "",
    required: true,
    error: false,
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "80%",
    md: 400,
  },
  bgcolor: "background.paper",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};

const Chats = () => {
  const slug = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [isConnected, setIsConnected] = useState(socket.connected);
  const [searchBar, setSearchBar] = useState<any>(SearchBarForm);
  const [ChatsList, setChatsList] = useState<any>([]);
  const [FilterChatsList, setFilterChatsList] = useState<any>([]);
  const [newMessage, setNewMessage] = useState("");
  const [ChatScreenUser, setChatScreenUser] = useState<any>({});
  const [messages, setMessages] = useState<any>([]);
  const [isLoading_chat, setIsLoadingChat] = useState(true);
  const PerosonInfo = useSelector(
    (state: { anotherUser: any }) => state?.anotherUser.ChatUserInfo
  );
  const [status, setStatus] = useState(false);
  const [is_loading, setIs_loading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const socketRef = useRef<any>(null);
  const [previewImage, setPreviewImage] = useState("");

  useEffect(() => {
    // Initialize and connect the socket
    const newSocket = initializeSocket();
    socketRef.current = newSocket; // Store the socket instance in the ref
    newSocket.connect();

    // Emit events and listen for responses after connecting
    newSocket.on("connect", () => {
      newSocket.emit("get_chat_contact");
      newSocket.on("get_chat_contact", (data) => {
        console.log(data);
        setIsLoadingChat(true);
        setChatsList(data);
        setFilterChatsList(data);
        setIsLoadingChat(false);
      });
      newSocket.on("get_chat_contact", (data) => {
        setIsLoadingChat(true);
        setChatsList(data);
        setIsLoadingChat(false);
      });

      newSocket.on("status", (data) => {
        // console.log('status', data, "ChatScreenUser", ChatScreenUser)
        setStatus(data.message.status);
        if (ChatScreenUser._id === data?.message?._id) {
          // console.log('ChatScreenUser inner', data?.message?._id)
          setChatScreenUser({
            ...ChatScreenUser,
            status: data.message.status,
          });
        }
      });
      newSocket.on("seen_message", (data: any) => {
        // console.log('seen_message', data)
      });
    });

    // Cleanup function to disconnect the socket on component unmount
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current.emit("active_room_id", { room_id: "" });
        setChatScreenUser({});
      }
    };
  }, []);

  const handleSendMessage = (imageURL: any) => {
    // console.log("handleSendMessage", imageURL);

    if (!imageURL && newMessage.trim() === "") return;

    const data = {
      message: imageURL ? "" : newMessage,
      receiver_id: ChatScreenUser?.anotherUser,
      type: imageURL ? "image" : "text",
      room_id: ChatScreenUser?.room_id ?? "",
      media_url: imageURL ? imageURL : "",
    };
    // console.log("handleSendMessage", data);
    socketRef.current.emit("message", data);

    setNewMessage("");
  };
  const getConciseTimeAgo = (messageTime: any) => {
    const duration = moment.duration(moment().diff(moment(messageTime)));
    const minutes = duration.asMinutes();
    const hours = duration.asHours();
    const days = duration.asDays();

    if (minutes < 1) {
      return "just now"; // Show "just now" if less than 1 minute
    } else if (minutes < 60) {
      return `${Math.floor(minutes)} min`;
    } else if (hours < 24) {
      return `${Math.floor(hours)} hr`;
    } else {
      return `${Math.floor(days)} day${Math.floor(days) > 1 ? "s" : ""}`;
    }
  };

  useEffect(() => {
    const userDetails = ChatsList?.filter((x: any) => x?._id === slug.id);
    if (userDetails.length === 0) {
      setChatScreenUser({
        first_name: PerosonInfo?.first_name,
        last_name: PerosonInfo?.last_name,
        status: status,
        _id: PerosonInfo?._id,
        room_id: PerosonInfo?.room_id ?? "",
        image: PerosonInfo?.image,
        isUser: PerosonInfo?.isUser,
        anotherUser: PerosonInfo?.anotherUser,
      });
    } else {
      setChatScreenUser({
        first_name: userDetails[0]?.first_name,
        last_name: userDetails[0]?.last_name,
        status: userDetails[0]?.status,
        _id: userDetails[0]?._id,
        room_id: userDetails[0]?.last_message?.room_id,
        image: userDetails[0]?.image,
        isUser: userDetails[0]?.last_message.user_id,
        anotherUser: userDetails[0]?.last_message.person_id,
      });
    }
  }, [slug.id]);

  useEffect(() => {
    if (slug.id) {
      socketRef.current.emit("status", {
        receiver_id: ChatScreenUser._id,
      });
      socketRef.current.on("status", (data: any) => {
        if (ChatScreenUser._id === data?.message?._id) {
          setChatScreenUser({
            ...ChatScreenUser,
            status: data.message.status,
          });
        }
      });
    }
  }, [ChatScreenUser.status]);

  useEffect(() => {
    const handleMessage = (data: any) => {
      // console.log("New Message", data);
      setChatScreenUser({
        ...ChatScreenUser,
        room_id: data?.room_id,
      });

      const newMessageshistory = {
        id: data._id,
        text: data?.message,
        time: getConciseTimeAgo(data.createdAt),
        isUser: data.sender_id === ChatScreenUser.isUser,
        type: data.type,
        media_url: data.media_url,
        seen: data?.seen,
      };
      setMessages((prevMessages: any) => [...prevMessages, newMessageshistory]);
    };
    // Set up the socket listener when the component mounts
    socketRef.current.on("message", handleMessage);

    // Clean up the listener on component unmount
    return () => {
      socketRef.current.off("message", handleMessage);
    };
  }, [ChatScreenUser]);

  useEffect(() => {
    const getRoomActive = (data: any) => {
      socketRef.current.emit("active_room_id", data);
      socketRef.current.on("active_room_id", (data: any) => {
        // console.log('active_room_id', data)
      });
    };

    if (ChatScreenUser?.room_id) {
      getRoomActive({ room_id: ChatScreenUser.room_id });
    }
  }, [ChatScreenUser?.room_id]);

  useEffect(() => {
    const getChathistory = (data: any, id: any) => {
      socketRef.current.emit("chat_history", data);
      socketRef.current.on("chat_history", (data: any) => {
        const newMessageshistory = data.map((x: any) => {
          return {
            id: x._id,
            text: x?.message,
            time: getConciseTimeAgo(x.createdAt),
            isUser: x.sender_id === id ? true : false,
            seen: x.seen,
            type: x.type,
            media_url: x.media_url,
          };
        });
        setMessages(newMessageshistory);
      });
    };
    const getSeenMessage = (data: any, id: any) => {
      socketRef.current.emit("seen_message", data);
      socketRef.current.on("seen_message", (data: any) => {});
    };
    // console.log("hello", ChatScreenUser)
    if (slug.id && ChatScreenUser.room_id && ChatScreenUser?.isUser) {
      getSeenMessage(
        {
          room_id: ChatScreenUser?.room_id,
          receiver_id: ChatScreenUser?.anotherUser,
        },
        ChatScreenUser?.isUser
      );
      const data = { room_id: ChatScreenUser?.room_id, page: 1, limit: 10 };
      getChathistory(data, ChatScreenUser?.isUser);
      // console.log("hello")
    }
  }, [slug.id, ChatScreenUser?._id, ChatScreenUser?.isUser]);

  useEffect(() => {
    const getContactChat = () => {
      socketRef.current.emit("get_chat_contact");
      socketRef.current.on("get_chat_contact", (data: any) => {
        setChatsList(data);
      });
    };
    getContactChat();
  }, [ChatScreenUser]);

  const chattoperson = async (id: any) => {
    const userDetails = ChatsList?.filter((x: any) => x?._id === id);
    const req = {
      first_name: userDetails[0]?.first_name,
      last_name: userDetails[0]?.last_name,
      status: userDetails[0]?.status,
      _id: userDetails[0]?._id,
      room_id: userDetails[0]?.last_message?.room_id,
      image: userDetails[0]?.image,
      isUser: userDetails[0]?.last_message.user_id,
      anotherUser: userDetails[0]?.last_message.person_id,
    };
    dispatch(updateChatuser(req));
    navigate(`/profile/chats/${id}`);
  };

  const lastMessageRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" }); // Scroll smoothly to the last message
    }
  }, [messages]);
  // console.log(ChatScreenUser)

  const handleFileUpload = async (event: any) => {
    // console.log("Testing", event.target.files?.[0])
    setOpenModal(true);
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      const url = "user/upload-image";
      const token = localStorage.getItem("token") ?? "";

      setIs_loading(true);
      // Send the form data via the postRequest function
      const response = await postRequest(url, formData, token);
      if (response.status) {
        setPreviewImage(response.data.Location);
      }
      setIs_loading(false);
    }
  };

  const handleConfirmUpload = () => {
    handleSendMessage(previewImage);
    setOpenModal(false);
    setPreviewImage("");
  };

  const messageEndRef = useRef<any>(null);
  const messageBoxRef = useRef<any>(null);

  useEffect(() => {
    // Scroll to the bottom of the message box
    if (messageBoxRef.current && messageEndRef.current) {
      messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if (searchBar?.search.value) {
      const filteredChats = ChatsList.filter((chat: any) => {
        const searchValue = searchBar?.search.value.trim().toLowerCase();
        const searchParts = searchValue.split(" ");

        if (searchParts.length === 1) {
          // Single-word search: check if the word is in either first_name or last_name
          return (
            chat.first_name.toLowerCase().includes(searchValue) ||
            chat.last_name.toLowerCase().includes(searchValue)
          );
        } else {
          // Multi-word search: check if each word is in either first_name or last_name
          return searchParts.every(
            (part: any) =>
              chat.first_name.toLowerCase().includes(part) ||
              chat.last_name.toLowerCase().includes(part)
          );
        }
      });
      setFilterChatsList(filteredChats);
    } else {
      setFilterChatsList(ChatsList);
    }
  }, [searchBar]);

  return (
    <>
      <div className="Chat-Page">
        <Box
          className="Chat-sidebarList"
          sx={{
            width: {
              xs: "100%",
              md: "30%",
            },
            display: {
              xs: !slug.id ? "flex" : "none",
              md: "flex",
            },
            padding: {
              xs: "10px",
              md: "10px 20px 2px 0px",
            },
          }}
        >
          <Box className="Chat-sidebarList-Navbar">
            <Typography variant="h1">Messages</Typography>
          </Box>
          <Box sx={{ width: "100%" }}>
            {Object.keys(searchBar).map((key: string, ind: number) => {
              return (
                <div key={ind}>
                  <InputWithIcon
                    imageSrc={searchBar[key].image}
                    placeholder={searchBar[key].placeholder}
                    typeOfinput={searchBar[key].type}
                    message={searchBar[key].message}
                    value={searchBar[key].value}
                    required={searchBar[key].required}
                    error={searchBar[key].error}
                    onChangeFunction={(e: any) => {
                      setSearchBar({
                        ...searchBar,
                        [key]: {
                          ...searchBar[key],
                          value: e,
                          error: false,
                          message: "",
                        },
                      });
                    }}
                  />
                </div>
              );
            })}
          </Box>
          <Box sx={{ width: "100%" }} className="ChatList-Holder">
            {isLoading_chat ? (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Loader size={30} />{" "}
              </Box>
            ) : (
              FilterChatsList.map((x: any, ind: any) => (
                <Box
                  className="Chat-sidebar-chatlist"
                  sx={{
                    padding: {
                      xs: "10px 0px",
                      md: "0px",
                    },
                  }}
                  key={ind}
                  onClick={() => chattoperson(x?._id)}
                >
                  <Box className="Chat-section-1">
                    <IconButton className="Chat-avatar">
                      <Avatar
                        alt="Remy Sharp"
                        src={x.image}
                        className="chat-img"
                      />
                    </IconButton>
                    <div className="Chat-user-details">
                      <Typography
                        variant="h3"
                        sx={{
                          fontWeight: 700,
                        }}
                      >
                        {x.first_name} {x.last_name}
                      </Typography>
                      <Typography
                        variant="h5"
                        sx={{
                          fontSize: "15px",
                        }}
                      >
                        {x.last_message?.type === "image"
                          ? "Media"
                          : x.last_message.message}
                      </Typography>
                    </div>
                  </Box>

                  <Box className="Chat-section-2">
                    <Typography
                      variant="h5"
                      sx={{
                        fontSize: "11px", // Corrected from '11x' to '11px'
                        whiteSpace: "nowrap", // Prevents text from wrapping
                        overflow: "hidden", // Hides overflow content
                        textOverflow: "ellipsis", // Adds ellipsis (...) when text overflows
                      }}
                    >
                      {getConciseTimeAgo(x.last_message.message_time)}
                    </Typography>
                    {x.last_message.unseen_message_count !== 0 && (
                      <div className="count">
                        {x.last_message.unseen_message_count}
                      </div>
                    )}
                  </Box>
                </Box>
              ))
            )}
          </Box>
        </Box>
        {slug?.id && (
          <Box
            className="Chat-messageList"
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              padding: "0 16px",
              width: {
                xs: "100%",
                md: "70%",
              },
              // backgroundColor: "#f0f0f0",
            }}
          >
            {/* Chat Header */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "8px",
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
            >
              {" "}
              <IconButton className="Chat-avatar">
                <Avatar
                  alt={ChatScreenUser.first_name}
                  src={ChatScreenUser.image}
                  className="chat-img"
                />
              </IconButton>
              <div>
                <Typography variant="h6" sx={{ marginLeft: "8px" }}>
                  {ChatScreenUser.first_name} {ChatScreenUser.last_name}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ marginLeft: "8px" }}
                >
                  {ChatScreenUser.status ? "Online" : "Offline"}
                </Typography>
              </div>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between", // To spread out the items
              }}
            >
              <Divider sx={{ flex: 1 }} /> {/* This creates the left line */}
              <Typography
                variant="body2"
                sx={{ margin: "0 8px", color: "text.secondary" }}
              >
                Today
              </Typography>
              <Divider sx={{ flex: 1 }} /> {/* This creates the right line */}
            </Box>
            {/* Message List */}
            <Box
              ref={messageBoxRef}
              sx={{ overflowY: "auto", height: "80%" }}
              className="Message-Box"
            >
              {messages.map((message: any, ind: any) => {
                return (
                  <Box
                    key={ind}
                    sx={{
                      display: "flex",
                      justifyContent: message.isUser
                        ? "flex-end"
                        : "flex-start",
                      marginBottom: "8px",
                      flexDirection: "column",
                      alignItems: message.isUser ? "flex-end" : "flex-start",
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: "70%",
                        padding: "12px",
                        borderRadius: "16px",
                        backgroundColor: message.isUser ? "#112848" : "#fff",
                        color: message.isUser ? "#fff" : "#000",
                        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                      }}
                    >
                      {message?.media_url?.length > 0 &&
                      message.type === "image" ? (
                        <>
                          {message?.media_url?.map((media: any) => {
                            return (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={media}
                                  alt="sent image"
                                  style={{
                                    width: "200px",
                                    height: "150px",
                                    objectFit: "cover",
                                    borderRadius: "8px",
                                  }}
                                />
                              </Box>
                            );
                          })}
                        </>
                      ) : (
                        <Typography
                          variant="body1"
                          sx={{ marginBottom: "4px" }}
                        >
                          {message.text}
                        </Typography>
                      )}
                    </Box>

                    <Typography
                      variant="caption"
                      color="textSecondary"
                      sx={{ textAlign: "right", display: "block" }}
                    >
                      {message.time}
                      {message.isUser && (
                        <DoneAllTwoToneIcon
                          sx={{ color: message.seen ? "blue" : "gray" }}
                        />
                      )}
                    </Typography>
                  </Box>
                );
              })}
              <div ref={messageEndRef} />
            </Box>

            {/* Message Input */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "8px",
                backgroundColor: "#fff",
                borderRadius: "16px",
                height: "100px",
              }}
            >
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                id="file-upload"
                onChange={handleFileUpload}
              />
              <label htmlFor="file-upload">
                <IconButton component="span">
                  <AttachFileIcon />
                </IconButton>
              </label>
              <TextField
                fullWidth
                placeholder="Your message"
                variant="outlined"
                size="small"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSendMessage(null);
                  }
                }}
                sx={{ marginLeft: "8px", marginRight: "8px" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => handleSendMessage(null)}>
                        <SendIcon color="primary" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        )}
      </div>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-preview-title"
        aria-describedby="modal-preview-description"
      >
        <Box sx={style}>
          <Typography id="modal-preview-title" variant="h6" component="h2">
            Image Preview
          </Typography>

          <Box
            sx={{
              maxWidth: "100%",
              maxHeight: "300px",
              minHeight: "300px",
              mt: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {is_loading ? (
              <Loader size={50} />
            ) : (
              <img
                src={previewImage}
                alt="Preview"
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "8px",
                  maxHeight: "300px",
                  objectFit: "contain",
                }}
              />
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              mt: 3,
            }}
          >
            <Box style={{ width: "45%" }}>
              <CustomButton
                variants="outlined"
                loading={false}
                label="Cancel"
                disabled={false}
                onClickFunction={() => setOpenModal(false)}
              />
            </Box>
            <Box style={{ width: "45%" }}>
              <CustomButton
                variants="contained"
                loading={false}
                label="Confirm"
                disabled={false}
                onClickFunction={handleConfirmUpload}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Chats;
