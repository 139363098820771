import { useState, useEffect } from "react";
import { Typography, Box } from '@mui/material';
import { useNavigate, useLocation,useSearchParams } from 'react-router-dom';
import CustomButton from "../../../Component/button";
import spotify from "../../../logo/spotify.svg";
import youtube from "../../../logo/youtube.svg";
import amazon from "../../../logo/amazon.svg";
import hulu from "../../../logo/hulu.svg";
import messenger from "../../../logo/messenger.svg";
import streamlit from "../../../logo/streamlit.svg";
import appleTv from "../../../logo/appleTv.svg";
import "./connect.scss"
import SnackbarComponent from '../../../Component/snackbarCom';
import { postRequest, getRequest } from "../../../api/services";
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';




const buttonArray = [{ tittle: "Spotify", icon: spotify }, { tittle: "YouTube", icon: youtube }]

function Connect() {
    const navigate = useNavigate()
    const location = useLocation();
    const [code, setCode] = useState(null);
    const UserInfo = useSelector((state: { user: any }) => state?.user.UserInfo);
    const [is_loading_spotify, setIs_loading_spotify] = useState<boolean>(false)
    const [is_loading_youtube, setIs_loading_youtube] = useState<boolean>(false)
    const [is_loading_next, setIs_loading_next] = useState<boolean>(false)
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarStatus, setSnackbarStatus] = useState(false);
    const [spotifyConnected, setSpotifyConnected] = useState(false);
    const [youtubeConnected, setYoutubeConnected] = useState(false);
    const [searchParams] = useSearchParams();
    const codes = searchParams.get('code');   // Get the 'code' query param
    const state = searchParams.get('state'); // Get the 'state' query param
  
    useEffect(() => {
      if (codes && state) {
        console.log(`Code: ${code}, State: ${state}`);
        // You can use these parameters to call an API or handle authentication
      }
    }, [code, state]);

    const buttonArray = [{ tittle: "Spotify", icon: spotify, lable: spotifyConnected ? 'Connexted' : 'Connect' }, { tittle: "YouTube", icon: youtube, lable: youtubeConnected ? 'Connexted' : 'Connect' }]

    const ConnectToMedia = async (title: any) => {
        if (title === "Spotify") {
            if (!spotifyConnected) {
                await connectSpotifyauth()
            }

        } else {
            if (!youtubeConnected) {
                await connectYoutubeauth()
            }

        }
    }
    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const params = new URLSearchParams(window.location.search);
    const codeFromParams: any = params.get('code');
    // console.log("hit")

    useEffect(() => {
        if (codeFromParams && codeFromParams !== code) {
            setCode(codeFromParams);
        }
    }, [codeFromParams, code]);

    useEffect(() => {
        // console.log(code)
        if (code) {
            connectedSpotifyData(code);
        }
    }, [code]);

    const connectSpotifyauth = async () => {
        try {
            const url = "user/web-spotify-auth"
            const token = localStorage.getItem('token') ?? ""
            const req = {}
            const response = await postRequest(url, req, token);
            setSnackbarStatus(response.status)
            if (response.status) {
                window.location.href = response.url;
            } else {
                setSpotifyConnected(false)
                setSnackbarMessage(response.message);
                setSnackbarOpen(true);
            }
        } catch (error) {
            // console.log(error)
        }
    }

    const connectedSpotifyData = async (code: any) => {
        try {
            setIs_loading_spotify(true)
            const url = "user/web-spotify-callback"
            const token = localStorage.getItem('token') ?? ""
            const params = new URLSearchParams({
                code: code
            });
            const urlWithParams = `${url}?${params.toString()}`;
            const response = await getRequest(urlWithParams, token);
            setSnackbarStatus(response.status)
            if (response.status) {
                setSnackbarMessage(response.message);
                setSnackbarOpen(true);
                setSpotifyConnected(true)
                setIs_loading_spotify(false)
            } else {
                setSpotifyConnected(false)
                setSnackbarMessage(response.message);
                setSnackbarOpen(true);
                setIs_loading_spotify(false)
            }
        } catch (error) {
            setIs_loading_spotify(false)
        }
    }

    const connectYoutubeauth = useGoogleLogin({

        onSuccess: async tokenResponse => {
            const req = { accessToken: tokenResponse.access_token, clientId: "824563392946-gm5qpi6pts31ibi6r642lovjoop01o3e.apps.googleusercontent.com" }
            setIs_loading_youtube(true)
            const url = "user/youtube-data"
            const token = localStorage.getItem('token') ?? ""
            const response = await postRequest(url, req, token);
            setSnackbarStatus(response.status)
            if (response.status) {
                setYoutubeConnected(true)
                setSnackbarMessage(response.message);
                setSnackbarOpen(true);
                setIs_loading_youtube(false)
            } else {
                setYoutubeConnected(false)
                setSnackbarMessage(response.message);
                setSnackbarOpen(true);
                setIs_loading_youtube(false)
            }
        },
    });

    useEffect(() => {
        if (UserInfo?.is_youtube_connected) {
            setYoutubeConnected(true)
        } else {
            setYoutubeConnected(false)
        }
        if (UserInfo?.is_spotify_connected) {
            setSpotifyConnected(true)
        } else {
            setSpotifyConnected(false)
        }

    }, [UserInfo?.is_spotify_connected, UserInfo?.is_youtube_connected
    ])



    const isButtonDisabled = !(youtubeConnected);


    return (
        <div style={{ width: "100%" }}>
            <Box className="about_me_2_page" sx={{
                justifyContent: {
                    xs: 'space-around',
                    md: 'center'
                },
                height: {
                    xs: '100%',
                    md: "auto"
                }
            }} >
                <Typography
                    variant="h1"
                >
                    Connect Your Media
                </Typography>
                <Typography
                    variant="h3"
                >
                    Tell me about your self
                </Typography>

                <Box className="button_component" sx={{
                    padding: {
                        xs: '10px 10px',
                        md: '10px 20px',
                    },

                    width: {
                        xs: '90%',
                        md: '80%'
                    }
                }} >
                    <Box className="Text_details" sx={{
                        gap: {
                            xs: '20px',
                            md: "40px"
                        }
                    }}>
                        <img
                            src={spotify} // Replace with the actual path to the Google logo
                            alt="Spotify"
                            className="logo" // Adjust size and spacing
                        />
                        <Typography
                            variant="h3"
                        >
                            Spotify
                        </Typography>
                    </Box>

                    <Box className='buttonSize' sx={{
                        width: {
                            xs: '40%',
                            md: "30%"
                        }
                    }}>
                        <CustomButton variants={spotifyConnected ? "outlined" : "contained"} loading={is_loading_spotify} disabled={false} label={
                            spotifyConnected
                                ? "Connected"
                                : "Connect"

                        } onClickFunction={() => ConnectToMedia('Spotify')} />
                    </Box>

                </Box>
                <Box className="button_component" sx={{
                    padding: {
                        xs: '10px 10px',
                        md: '10px 20px',
                    },

                    width: {
                        xs: '90%',
                        md: '80%'
                    }
                }}>
                    <Box className="Text_details" sx={{
                        gap: {
                            xs: '20px',
                            md: "40px"
                        }
                    }}>
                        <img
                            src={youtube} // Replace with the actual path to the Google logo
                            alt="Spotify"
                            className="logo" // Adjust size and spacing
                        />
                        <Typography
                            variant="h3"
                        >
                            YouTube
                        </Typography>
                    </Box>

                    <Box className='buttonSize' sx={{
                        width: {
                            xs: '40%',
                            md: "30%"
                        }
                    }}>
                        <CustomButton variants={youtubeConnected ? "outlined" : "contained"} loading={is_loading_youtube} disabled={false} label={
                            youtubeConnected
                                ? "Connected"
                                : "Connect"

                        } onClickFunction={() => ConnectToMedia('YouTube')} />
                    </Box>

                </Box>


                <Typography
                    variant="h3"
                >
                    Other services coming soon
                </Typography>

                <div className="logo-container">
                    <div className="row top-row">
                        <img src={hulu} alt="Hulu Logo" className="logo hulu-logo" />
                    </div>
                    <div className="row middle-row">
                        <img src={appleTv} alt="Apple TV Logo" className="logo apple-tv-logo" />
                    </div>
                    <div className="row bottom-row">
                        <div>
                            <img src={streamlit} alt="Steam Logo" className="logo steam-logo" />
                            <img src={amazon} alt="Amazon Kindle Logo" className="logo amazon-kindle-logo" />
                        </div>

                        <img src={messenger} alt="Twitch Logo" className="logo twitch-logo" />
                    </div>
                </div>


                <CustomButton variants="contained" loading={is_loading_next} disabled={isButtonDisabled} label="Next" onClickFunction={() => navigate("/profile/matches")} />
                <SnackbarComponent
                    open={snackbarOpen}
                    message={snackbarMessage}
                    onClose={handleCloseSnackbar}
                    status={snackbarStatus}
                />

            </Box>
        </div >


    )
}

export default Connect